<template>
  <b-card class="d-flex flex-column justify-content-center align-items-start p-1 cc-bg-light">
    <b-row>
      <b-col cols="12" class="d-flex flex-row justify-content-between align-items-center p-0 mb-1">
        <div class="d-flex flex-row align-items-center mr-2 p-0">
          <h6>{{ invite.nome_contato }}</h6>
          <div v-if="invite.aceito">
            <b-badge pill class="alert-success border-success ml-2">
              Aceito
            </b-badge>
          </div>

          <div v-else>
            <b-badge v-if="invite.ultimo_envio" pill class="alert-warning border-warning ml-2">Aguardando
              cadastro</b-badge>
            <b-badge v-else pill class="alert-info border-info ml-2">
              Aguardando envio
            </b-badge>
          </div>
        </div>
        <div v-if="!invite.aceito" class="d-flex flex-row align-items-start p-0">
          <div class="d-flex flex-column align-items-start mr-2">
            <div id="copyTooltip" class="cursor-pointer" @click="copyLink">
              <b-badge class="alert-primary border-primary"><feather-icon icon="CopyIcon"
                                                                          size="20"
                                                                          color="#093272"
                                                                          class="mr-1"
              />Link de cadastro</b-badge>
              <b-tooltip ref="tooltip"
                         triggers="click"
                         target="copyTooltip"
                         title="Copiado!"
                         variant="primary"
                         :delay="{ show: 100, hide: 500 }"
              />
            </div>
          </div>
          <div class="d-flex flex-column align-items-start">
            <div class="cursor-pointer" @click="deleteInvite">
              <b-badge class="alert-primary border-primary"><feather-icon icon="TrashIcon"
                                                                          size="20"
                                                                          color="#093272"
                                                                          class="mr-1"
              />Excluir convite</b-badge>
            </div>
          </div>
        </div>
      </b-col>
      <div class="d-flex flex-row justify-content-between align-items-end p-0 w-100">
        <div class="d-flex flex-column align-items-start justify-content-center">
          <div class="d-flex flex-row align-items-start">
            <div class="d-flex flex-column align-items-start mr-2">
              <span>Último envio</span>
              <h6>{{ formatDate(invite.ultimo_envio) }}</h6>
            </div>
            <div class="d-flex flex-column align-items-start mr-2">
              <span>Telefone</span>
              <h6>{{ invite.celular_contato }}</h6>
            </div>
            <div class="d-flex flex-column align-items-start">
              <span>E-mail</span>
              <h6>{{ invite.email_contato }}</h6>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center p-0">
          <div v-if="!invite.aceito" class="d-flex flex-row align-items-start p-0">
            <b-button variant="primary" @click="sendInvite">{{
              invite.ultimo_envio ? "Reenviar convite" : "Enviar convite"
            }}</b-button>
          </div>
        </div>
      </div>
    </b-row>
  </b-card>
</template>

<style>
  .card-body { width: 100%; }
</style>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge, BTooltip, VBTooltip,
} from 'bootstrap-vue'
import { formatDate } from '@/@core/utils/filter'
import { testExpression, ISODateTimeExpression } from '@core/comp-functions/misc/regexp'

export default {
  name: 'InviteCard',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    invite: {
      type: Object,
      default: () => { },
    },
  },
  methods: {
    async sendInvite() {
      const isResend = !!this.invite.ultimo_envio
      const text = isResend ? 'Você está prestes a reenviar o convite para a empresa.' : 'Você está prestes a enviar o convite para a empresa.'
      const successText = isResend ? 'Convite reenviado com sucesso.' : 'Convite enviado com sucesso.'
      try {
        this.$swal
          .fire({
            title: 'Deseja continuar?',
            text,
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: isResend ? 'Reenviar' : 'Enviar',
          })
          .then(async result => {
            if (result.value) {
              this.$swal.showLoading()
              await this.$store.dispatch('company/resendInvite', this.invite.id)
              this.$emit('resent')
              this.$swal.fire('Pronto!', successText, 'success')
            }
          })
      } catch (error) {
        this.$swal.fire('Oops...', 'Ocorreu um erro ao enviar o convite.', 'error')
      }
    },
    async deleteInvite() {
      const companyId = this.$route.params.id
      const inviteId = this.invite.id
      try {
        this.$swal
          .fire({
            title: 'Deseja continuar?',
            text: 'Você está prestes a deletar o convite.',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Sim, deletar!',
          })
          .then(async result => {
            if (result.value) {
              this.$swal.showLoading()
              await this.$store.dispatch('company/deleteInvite', { companyId, inviteId })
              this.$emit('deleted')
              this.$swal.fire('Pronto!', 'Convite deletado com sucesso.', 'success')
            }
          })
      } catch (error) {
        this.$swal.fire('Oops...', 'Ocorreu um erro ao deletar o convite.', 'error')
      }
    },
    copyLink() {
      this.$copyText(this.invite.url_convite).then(() => {
        setTimeout(() => this.$refs.tooltip.$emit('close'), 750)
      })

      setTimeout(() => {
        this.$refs.tooltip.$emit('close')
      }, 750)
    },
    haveLessThan24hours() {
      const lastSend = new Date(this.invite.ultimo_envio)
      const now = new Date()
      const diff = now.getTime() - lastSend.getTime()
      const diffHours = diff / (1000 * 3600)
      return diffHours < 24
    },
    formatDate() {
      if (!this.invite.ultimo_envio) return 'Não enviado'

      if (testExpression(ISODateTimeExpression, this.invite.ultimo_envio)) {
        return new Date(this.invite.ultimo_envio).toLocaleDateString('pt-BR', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })
      }

      return formatDate(this.invite.ultimo_envio)
    },
  },
}
</script>
